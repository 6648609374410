import BigNumber from 'bignumber.js'
import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Text from 'UIKit/Text'
import TokenLogo from 'UIKit/TokenLogo'
import tokens from 'config/constants/tokens'
import { Token } from 'config/types'
import { Transaction, TransactionTypeEnum } from 'config/types/transaction'
import { Trans } from 'react-i18next'
import { Icons } from 'svgs'
import theme from 'theme'
import { colors } from 'theme/colors'
import { getFullDisplayBalance } from 'utils/formatBalance'

const PendingTransaction: React.FC<{ transaction: Transaction }> = ({ transaction }) => {
  const token = tokens[transaction.network][transaction.currency] as Token
  const displayAmount = getFullDisplayBalance(new BigNumber(transaction.value).minus(transaction.fee || 0), 0, 6)

  return (
    <Box minWidth="250px">
      <Flex alignItems="center">
        <Box
          mr="12px"
          height="100%"
          padding="6px"
          border={`1px solid ${colors.info}`}
          background={`${colors.info}33`}
          borderRadius={theme.radii.tiny}
        >
          <Icons.HourGlassIcon height="24px" fill={colors.info} />
        </Box>
        <Flex flexDirection="column" alignItems="flex-start" justifyContent="flex-end">
          <Text color="info" fontSize="16px" bold>
            {transaction.type === TransactionTypeEnum.Deposit ? (
              <Trans>Deposit in Progress!</Trans>
            ) : (
              <Trans>Withdrawal in Progress!</Trans>
            )}
          </Text>
          <Flex mt="4px" alignItems="center">
            <TokenLogo token={token} width="20px" />
            <Text ml="6px" fontSize="14px">
              {displayAmount} {token.name}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

export default PendingTransaction
