import { GlobalUpdater, StateUpdater } from 'Updater'
import Loader from 'UIKit/Loader/Loader'
import { FetchingStatus, TOAST_CONFIG } from 'config/constants'
import ModalsProvider from 'contexts/Modals'
import { SolanaWalletProvider } from 'contexts/Solana/SolanaWalletProvider'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import resources from 'i18n/resources'
import i18n from 'i18next'
import { useRouter } from 'next/router'
import { WagmiProvider } from 'packages/wagmi/WagmiProvider'
import { wagmiConfig } from 'packages/wagmi/wagmi'
import InitialServiceDetails from 'providers/InitialServiceDetails'
import InitialSiteConfig from 'providers/InitialSiteConfig'
import { SDKProvider } from '@telegram-apps/sdk-react'

import { useEffect, useState } from 'react'
import { initReactI18next, useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, useAppDispatch } from 'state'
import { setInitializeSiteStatus } from 'state/app/actions'
import { useSolNetworkInfo } from 'state/app/hooks'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from 'styles/Global'
import ResetCSS from 'styles/ResetCSS'
import theme from 'theme'
import TelegramUpdater from 'providers/TelegramUpdater'

const Providers = ({ store, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ResetCSS />
      <GlobalStyle />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SDKProvider>
            <I18nProvider>
              <Web3Provider>
                <ModalsProvider>
                  <InitialServiceDetails>
                    <InitialSiteConfig>
                      <TelegramUpdater>
                        <StateUpdater />
                      </TelegramUpdater>
                    </InitialSiteConfig>
                  </InitialServiceDetails>
                  <GlobalUpdater />

                  {children}
                  <ToastContainer {...TOAST_CONFIG} />

                  <Loader />
                </ModalsProvider>
              </Web3Provider>
            </I18nProvider>
          </SDKProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  )
}

const I18nProvider = ({ children }) => {
  const router = useRouter()

  const [isLanguageInitialized, setIsLanguageInitialized] = useState(false)

  useIsomorphicEffect(() => {
    const init = async () => {
      await i18n.use(initReactI18next).init({
        resources,
        lng: router.locale,
        interpolation: {
          escapeValue: false,
        },
      })
      setIsLanguageInitialized(true)
    }
    init()
  }, [])

  return isLanguageInitialized && <I18nEventListener> {children}</I18nEventListener>
}

const I18nEventListener = ({ children }) => {
  const router = useRouter()
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const { locale, isReady } = router

    if (i18n.language === locale || !isReady) return

    setLoading(true)
    dispatch(
      setInitializeSiteStatus({
        status: FetchingStatus.Unknown,
      }),
    )
    setTimeout(() => {
      i18n.changeLanguage(locale)
      setLoading(false)
    }, 12)
  }, [i18n.language, router.locale, router.isReady])

  return router.isReady && !loading && children
}

const Web3Provider = ({ children }) => {
  const solNetwork = useSolNetworkInfo()

  return (
    <WagmiProvider config={wagmiConfig}>
      <SolanaWalletProvider endpoint={solNetwork.rpcCollections[0]}>{children}</SolanaWalletProvider>
    </WagmiProvider>
  )
}

export default Providers
