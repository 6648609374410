import { NO_CONTENT, PROCESS_CLASS_NAME, SHOW_MORE_BTN_CLASS_NAME } from 'UIKit/HunnyList/ListStyleUpdater'
import { createGlobalStyle } from 'styled-components'
import theme from 'theme'
import { HunnyTheme } from 'theme/types'

declare module 'styled-components' {
  export interface DefaultTheme extends HunnyTheme {}
}

const GlobalStyle = createGlobalStyle`

  :root {  
    --screen-height: ${() => (typeof window !== 'undefined' ? `${window.innerHeight}px` : '100vh')};
    --notification-bar-height: 0px;
  }

  html {
    scrollbar-track-color: transparent;
    scrollbar-arrow-color: transparent;
    overflow-y: scroll;
  }

  body {
    margin: 0px;
    min-height: var(--screen-height);
    display: flex;
    flex-direction: column;
    scroll-behavior: unset;
    overflow: hidden;
  }

  * { 
    scrollbar-color: ${({ theme }) => theme.colors.scroll} transparent;
    scrollbar-width: thin;
    -webkit-overflow-scrolling: touch;
    -webkit-overflow-scroll: touch;
  }


  html {
    scrollbar-width: thin;
  }

  @media only screen and (min-width: 969px) {
    html {
      scrollbar-width: auto !important;
    }
  }


  body {
    background:  ${({ theme }) => theme.colors.background};

    margin: 0;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    font-family: 'Montserrat';
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .hunny-lazy-list.${SHOW_MORE_BTN_CLASS_NAME}{
    position: relative;
    padding-bottom: 84px;
  }

  .hunny-lazy-list.${PROCESS_CLASS_NAME}{
    position: relative;
    padding-bottom: 72px;

    &.${SHOW_MORE_BTN_CLASS_NAME}{
      padding-bottom: 144px;
    }

    .hunny-list-loadmore-btn{
      position: absolute;
      left: 50%;
      bottom: 72px;
      transform: translateX(-50%) !important;
      height: 48px;
    }
  }

  .hunny-lazy-list.${NO_CONTENT}{
    padding: 64px !important;
    position: relative;
  }

 

  .hunny-lazy-list {
    padding-bottom: 24px;

    .hunny-list-loadmore-btn{
      position: absolute;
      left: 50%;
      bottom: 24px;
      transform: translateX(-50%) !important;
      height: 48px;
    }

    .hunny-list-process{
      position: absolute;
      left: 50%;
      bottom: 24px;
      transform: translateX(-50%) !important;
      height: 24px;
    }

    .list-no-content {
      position: absolute;
      left: 50%;
      top: 0;
      height: 100%;
      transform: translateX(-50%) !important;
    }
  }


  

  .Toastify {
    font-size: 16px;
    font-weight: 600;

    .Toastify__toast-container{
      width: auto;
      max-width: 500px;
      flex-direction: column;
      display: flex;
      align-items: flex-end;
    }
  }

  .Toastify__close-button--default {
    color: #fff;
  }

  @media only screen and (max-width: 600px) {
    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
      top: 60px !important;
      padding: 0px 12px !important;
    }
  }

  .Toastify__toast-container--top-right {
    top: 60px !important;
    right: 1em !important;
  }

  .Toastify__notification-container {
    padding: 0px;

    .Toastify__notification-body-container {
      padding: 0px;
    }

    .Toastify__close-button {
      position: relative;
      top: 10px;
      right: 10px;
    }
  }

  @media (max-width: 767px) {
    body {
      margin-bottom: 56px;
    }
  }

  .react-tooltip:not(.custom-tip) {
      background: #1d2533 !important;
      border-radius: 8px;
      z-index: 1000;
  }

  .custom-tip {
    padding: 0px !important;
  }

  .react-tooltip {
    opacity: 1 !important;
    max-width: 562px;
    font-size: 14px;
    line-height: 1.2;
  }

  .custom-arrow-tip {
    background: ${theme.colors.modalHeaderBackground} !important;
    width: 20px !important;
    height: 20px !important;
    z-index: 1;
  }


  @media (max-width: 562px) {
    .react-tooltip {
      opacity: 1 !important;
      left: 12px !important;
      width: 100% !important;
      max-width: calc(100% - 24px) !important;
      box-sizing: border-box;
    }

  }

  .hide-scroll {
    scrollbar-width: none;
  }
  

  /* body.modal-open .content-blurable {
    transition: filter 0.2s;
    filter: blur(6px);
  } */

  #crisp-chatbox > div > a:has([data-id="chat_closed"]), a:has([data-id="chat_opened"]) {
    display: none !important;
  }

  #crisp-chatbox > div a:nth-child(2) {
    display: none !important;
  }

  #crisp-chatbox .container-crisp.container-button-crisp > a {
    display: block !important;
    transform: scale(0.8);
  }
  

  #crisp-chatbox .container-crisp.betby>a {
    bottom: 60px !important;
    transform: scale(0.9);
  }

  @media only screen and (max-width: 856px) {
    #crisp-chatbox div:not(.container-crisp)>a {
      display: none !important;
    }

    #crisp-chatbox .container-crisp.container-button-crisp>a {
      display: none !important;
    }

    #crisp-chatbox>.container-crisp>div {
      bottom: 80px !important;
    }

    #crisp-chatbox .container-crisp.container-button-crisp>a {
      bottom: 80px !important;
      transform: scale(0.9);
    }

    #crisp-chatbox .container-crisp.container-button-crisp>div {
      bottom: 140px !important;
    }

    #crisp-chatbox .sport-main a {
      bottom: 80px !important;
      transform: scale(0.8);
    }

    #crisp-chatbox .sport-main>div {
      bottom: 140px !important;
    }
  }

  @media only screen and (max-width: 480px) {
    #crisp-chatbox>.container-crisp>div {
      bottom: 0 !important;
    }

    #crisp-chatbox .container-crisp.container-button-crisp>div {
      bottom: 0 !important;
    }
  }
`

export default GlobalStyle
